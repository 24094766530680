import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Badge } from '@material-ui/core';
import { Datatable } from '../../components';

export const TradesBounced = ({ data, tradesLoading }) => {
  const PaddedBadge = withStyles((theme) => ({
    root: {
      marginLeft: theme.spacing(2),
    },
  }))(Badge);

  const tableConfig = [
    {
      sortable: true,
      heading: 'Status',
      key: 'status',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Participant Name',
      key: 'participantName',
      formatter: (val, row) =>
        row?.expirationCount > 0 ? (
          <span>
            {val}
            <Tooltip title="Recycle Count">
              <PaddedBadge badgeContent={row.expirationCount} color="error" />
            </Tooltip>
          </span>
        ) : (
          val
        ),
    },
    {
      sortable: true,
      heading: 'Participant ID',
      key: 'participantId',
      formatter: (val) => val,
    },
    { heading: 'Product', key: 'product', formatter: (val) => val },
    { heading: 'Type', key: 'type', formatter: (val) => val },
  ];

  return (
    <Datatable
      loading={tradesLoading}
      tableHeading="Trades Bounced"
      defaultSortColumn="created"
      defaultSortDirection="desc"
      data={data}
      tableConfig={tableConfig}
      paginationLeft={true}
    />
  );
};
