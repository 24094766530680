import React, { useState } from 'react';

import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Button,
  IconButton,
  Badge,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LoopIcon from '@material-ui/icons/Loop';
import { red } from '@material-ui/core/colors';
import { Datatable, BaseModal } from '../../components';

export const TradesNeeded = ({
  data,
  sendSMS,
  updateStatus,
  tradesLoading,
}) => {
  const [selected, setSelected] = useState([]);
  const [jobToAnnul, setJobToAnnul] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setJobToAnnul('');
  };

  const handleAnnul = () => {
    updateStatus(jobToAnnul, 'annulled');
  };

  const PaddedBadge = withStyles((theme) => ({
    root: {
      marginLeft: theme.spacing(2),
    },
  }))(Badge);

  const tableConfig = [
    {
      sortable: true,
      heading: 'Participant Name',
      key: 'participantName',
      formatter: (val, row) =>
        row?.expirationCount > 0 ? (
          <span>
            {val}
            <Tooltip title="Recycle Count">
              <PaddedBadge badgeContent={row.expirationCount} color="error" />
            </Tooltip>
          </span>
        ) : (
          val
        ),
    },
    {
      sortable: true,
      heading: 'Participant ID',
      key: 'participantId',
      formatter: (val) => val,
    },
    {
      sortable: true,
      heading: 'Queue Date',
      key: 'created',
      formatter: (val) => moment(val).format('MM/DD/YYYY'),
    },
    {
      sortable: true,
      heading: 'Time Lapse',
      key: 'lastUpdate',
      formatter: (val) => val,
    },
    { heading: 'Product', key: 'product', formatter: (val) => val },
    { heading: 'Type', key: 'type', formatter: (val) => val },
    {
      sortable: true,
      heading: 'Advisor',
      key: 'advisorName',
    },
    {
      heading: 'Send Trade',
      key: 'id',
      formatter: (val) => (
        <Checkbox
          color="primary"
          checked={selected.includes(val)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onClick={() => {
            setSelected((prev) =>
              prev?.includes?.(val)
                ? [...prev.filter((i) => i !== val)]
                : [...prev, val]
            );
          }}
        />
      ),
    },
    {
      heading: '',
      key: 'id',
      headingStyles: { width: '25px' },
      formatter: (val) => (
        <IconButton
          aria-label="delete"
          onClick={() => {
            setJobToAnnul(val);
            setOpen(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[700]),
      backgroundColor: red[700],
      '&:hover': {
        backgroundColor: red[900],
        borderColor: red[900],
      },
      borderColor: red[900],
    },
  }))(Button);

  return (
    <>
      <BaseModal
        handleClose={handleClose}
        isOpen={open}
        title={'Confirm Trade Removal'}
        description={
          'This will mark the job as annulled and remove it from the table. This is typically performed when a speakeasy job has been queued for a user whose account does not have MFA enabled or when a job is no longer required'
        }
        customPrimaryAction={
          <ColorButton color="primary" onClick={handleAnnul}>
            {'Remove Trade'}
          </ColorButton>
        }
        customSecondaryAction={
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {'Cancel'}
          </Button>
        }
      />
      <Datatable
        loading={tradesLoading}
        tableHeading="Trades Needed"
        hasSearch={true}
        defaultSortColumn="created"
        defaultSortDirection="desc"
        data={data}
        tableConfig={tableConfig}
        paginationLeft={true}
        footerContent={
          <Button
            disabled={!(selected?.length > 0)}
            color="primary"
            onClick={() => sendSMS(selected)}
          >
            Send SMS
          </Button>
        }
      />
    </>
  );
};
