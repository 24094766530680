import React, { useState, useEffect, useRef, createContext } from 'react';

export const TimerContext = createContext(null);

function setTime(amount, units) {
  const deadline = new Date();
  switch (units) {
    case 'hours':
    case 'hour':
    case 'h':
      deadline.setHours(deadline.getHours() + amount);
      break;
    case 'minutes':
    case 'minute':
    case 'm':
      deadline.setMinutes(deadline.getMinutes() + amount);
      break;
    case 'seconds':
    case 'second':
    case 's':
      deadline.setSeconds(deadline.getSeconds() + amount);
      break;
    case 'milliseconds':
    case 'millisecond':
    case 'ms':
      deadline.setMilliseconds(deadline.getMilliseconds() + amount);
      break;
    default:
      deadline.setMilliseconds(deadline.getMilliseconds() + amount);
      break;
  }
  return deadline;
}

function getTimeRemaining(e) {
  const total = Date.parse(e) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
  };
}

function leftPad(str, count, char) {
  return `${Array(count - String(str).length + 1).join(char)}${str}`;
}

function digi(str) {
  return leftPad(str, 2, '0');
}

function digitize(hours, minutes, seconds) {
  return `${digi(hours)}:${digi(minutes)}:${digi(seconds)}`;
}

export function TimerProvider({ length, units, children }) {
  const Ref = useRef(null);

  const [timer, setTimer] = useState('00:00:00');

  const checkTime = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(digitize(hours, minutes, seconds));
    }
  };

  const stopTimer = () => {
    checkTime(setDeadline());
    if (Ref.current) clearInterval(Ref.current);
  };

  const startTimer = () => {
    stopTimer();
    const e = setDeadline();
    const id = setInterval(() => {
      checkTime(e);
    }, 1000);
    Ref.current = id;
  };

  const setDeadline = () => {
    return setTime(length, units);
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <TimerContext.Provider value={{ startTimer, stopTimer, timer }}>
      {children}
    </TimerContext.Provider>
  );
}
