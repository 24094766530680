import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TradesNeeded } from './TradesNeeded';
import { TradesInitiated } from './TradesInitiated';
import { TradesScheduled } from './TradesScheduled';
import { TradesBounced } from './TradesBounced';
import { AddSpeakeasyJob } from './AddSpeakeasyJob';
import { useSpeakeasyTrades } from '../../modules/Trades/SpeakeasyTrades';
import { AppContext } from '../../modules/AppContext';
import { RefresherContext } from '../../components/Refresher/Refresher';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    padding: '20px',
    margin: '0',
  },
}));

export const SpeakeasyTrades = () => {
  const { showToast, searchToken } = useContext(AppContext);
  const {
    setFetchingFunction,
    clearFetchingFunction,
    enableRefresher,
    disableRefresher,
  } = useContext(RefresherContext);

  const classes = useStyles();
  const tradesObj = useSpeakeasyTrades();
  const [tradeData, setTradeData] = useState({});
  const [tradesLoading, setTradesLoading] = useState(false);
  const [searchedTradeData, setSearchedTradeData] = useState({});

  const searchableColumns = [
    'advisorName',
    'participantName',
    'participantId',
    'type',
    'status',
    'product',
  ];

  const searchTrades = () => {
    let newTrades = { pending: [], inProgress: [], scheduled: [] };
    if (searchToken.length > 0) {
      const filterObject = (row) => {
        const matches = searchableColumns.filter((col) => {
          const field = row[col] || '';
          const isMatch = String(field)
            .toLowerCase()
            .includes(String(searchToken).toLowerCase());
          return isMatch;
        });
        return matches.length > 0;
      };
      newTrades.pending = tradeData?.pending?.filter(filterObject);
      newTrades.inProgress = tradeData?.inProgress?.filter(filterObject);
      newTrades.scheduled = tradeData?.scheduled?.filter(filterObject);
      newTrades.bounced = tradeData?.bounced?.filter(filterObject);
    } else {
      newTrades = tradeData;
    }
    setSearchedTradeData(newTrades);
  };

  const getTrades = () => {
    setTradesLoading(true);
    tradesObj
      .getTrades()
      .then(async (res) => {
        if (res.ok) {
          let data = [];
          try {
            data = (await res.json()) || [];
          } catch (err) {
            console.error(err);
            showToast({
              message: `Error fetching jobs:: ${err}`,
              type: 'error',
            });
          }
          setTradeData(data);
          setSearchedTradeData(data);
        } else {
          showToast({
            message: `Error fetching jobs`,
            type: 'error',
          });
        }
      })
      .finally(() => {
        setTradesLoading(false);
      });
  };

  const sendInviteWithRefresh = (args) => {
    tradesObj.sendInvite(args).then(async (res) => {
      if (res.ok) {
        showToast({
          message: `SMS sent successfully!`,
          type: 'success',
        });
        getTrades();
      } else {
        try {
          showToast({
            message: `Error sending SMS: ${(await res.text()) || ''}`,
            type: 'error',
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const updateStatusWithRefresh = (jobId, status) => {
    tradesObj.updateStatus(jobId, status).then(async (res) => {
      if (res.ok) {
        showToast({
          message: `Job updated successfully!`,
          type: 'success',
        });
        getTrades();
      } else {
        try {
          showToast({
            message: `Error updating status: ${(await res.text()) || ''}`,
            type: 'error',
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const createJob = (participantId, type) => {
    tradesObj.createJob(participantId, type).then(async (res) => {
      if (res.ok) {
        showToast({
          message: `Job created successfully!`,
          type: 'success',
        });
        getTrades();
      } else {
        try {
          showToast({
            message: `Error creating job: ${(await res.text()) || ''}`,
            type: 'error',
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  useEffect(() => {
    getTrades();
    setFetchingFunction(getTrades);
    enableRefresher();
    return () => {
      // on dismount;
      clearFetchingFunction();
      disableRefresher();
    };
  }, []);

  useEffect(() => {
    searchTrades();
  }, [searchToken]);

  return (
    <div className={classes.wrapper}>
      <TradesNeeded
        tradesLoading={tradesLoading}
        data={searchedTradeData.pending || []}
        sendSMS={sendInviteWithRefresh}
        updateStatus={updateStatusWithRefresh}
      />
      <TradesInitiated
        tradesLoading={tradesLoading}
        data={searchedTradeData.inProgress || []}
        updateStatus={updateStatusWithRefresh}
      />
      <TradesScheduled
        tradesLoading={tradesLoading}
        data={searchedTradeData.scheduled || []}
        sendSMS={sendInviteWithRefresh}
        updateStatus={updateStatusWithRefresh}
      />
      <TradesBounced
        tradesLoading={tradesLoading}
        data={searchedTradeData.bounced || []}
      />
      <AddSpeakeasyJob createJob={createJob} />
    </div>
  );
};
