import React, {
  useEffect,
  useRef,
  createContext,
  useContext,
  useState,
} from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SyncIcon from '@material-ui/icons/Sync';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles } from '@material-ui/core/styles';
import { TimerContext } from '../Timer/Timer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const RefresherContext = createContext(null);

export function Refresher() {
  const {
    refreshNow,
    timer,
    startRefresher,
    stopRefresher,
    isRefreshing,
    isEnabled,
  } = useContext(RefresherContext);

  const classes = useStyles();
  return (
    isEnabled && (
      <div className={classes.root}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={refreshNow}>
            <SyncIcon />
          </Button>
          <Button disabled>{timer}</Button>
          {isRefreshing ? (
            <Button onClick={stopRefresher}>
              <StopIcon />
            </Button>
          ) : (
            <Button onClick={startRefresher}>
              <PlayArrowIcon />
            </Button>
          )}
        </ButtonGroup>
      </div>
    )
  );
}

export function RefresherProvider({ interval = 60000, children }) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const Ref = useRef(null);
  const FnRef = useRef(() => null);

  const { startTimer, stopTimer, timer } = useContext(TimerContext);

  const stopRefresher = () => {
    setIsRefreshing(false);
    stopTimer();
    if (Ref.current) clearInterval(Ref.current);
  };

  const setFetchingFunction = (fn) => {
    if (typeof fn === 'function') {
      FnRef.current = fn;
      stopRefresher();
      startRefresher();
    }
  };

  const clearFetchingFunction = () => {
    stopRefresher();
    FnRef.current = () => null;
  };

  const fetchNow = () => {
    try {
      FnRef.current();
    } catch (err) {
      console.error(`Error invoking refresher function :: ${err}`);
    }
  };

  const fetchAndStartTimer = () => {
    startTimer();
    fetchNow();
  };

  const startRefresher = () => {
    stopRefresher();
    startTimer();
    const id = setInterval(() => {
      fetchAndStartTimer();
    }, interval);
    Ref.current = id;
    setIsRefreshing(true);
  };

  const refreshNow = () => {
    stopRefresher();
    fetchNow();
  };

  const enableRefresher = () => setIsEnabled(true);
  const disableRefresher = () => setIsEnabled(false);

  return (
    <RefresherContext.Provider
      value={{
        startRefresher,
        stopRefresher,
        timer,
        refreshNow,
        setFetchingFunction,
        clearFetchingFunction,
        isRefreshing,
        isEnabled,
        enableRefresher,
        disableRefresher,
      }}
    >
      {children}
    </RefresherContext.Provider>
  );
}
